import React from 'react';
import './nf.scss';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Login} from "./routes/login/login";
import {Dashboard} from "./routes/dashboard/dashboard";
import {Customers} from "./routes/dashboard/customers/customers";
import {Toaster} from "react-hot-toast";
import {Invoices} from "./routes/dashboard/invoices/invoices";
import {isLogin} from "./core/apis/api";
import {Reports} from "./routes/dashboard/reports/reports";

function NF() {
    return (
        <><Toaster position={"top-center"}/>

            <BrowserRouter>
                <div className="novin-finance">
                    <Routes>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/dashboard" element={<Dashboard/>}/>
                        <Route path="/dashboard/customers" element={<Dashboard children={<Customers/>}/>}/>
                        <Route path="/dashboard/invoices" element={<Dashboard children={<Invoices/>}/>}/>
                        <Route path="/dashboard/reports" element={<Dashboard children={<Reports/>}/>}/>
                        <Route path="/" element={isLogin() ? <Dashboard/> : <Login/>}/>

                    </Routes>
                </div>
            </BrowserRouter>
        </>
    );
}

export default NF;
