import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import './invoices-table.scss'
import {Button, ButtonType} from "../button/button";
import {Input} from "../input/input";
import {Enum, PaginationList} from "../../core/domain/commons/common";
import {API} from "../../core/apis";
import {del, getViaAuth, postViaAuth, responseValidator} from "../../core/apis/api";
import {Col, Form, Input as AntdInput, message, Modal, Row, Select, Table, Typography} from 'antd';
import {DeleteOutlined, EditOutlined, SearchOutlined} from "@ant-design/icons";
import {Invoice, InvoiceAddRequestBody} from "../../core/domain/invoice";
import {Customer} from "../../core/domain/customer";
import {deleteCommaSeparator, digitSeparator} from "core/utils/decimalExtentions";

interface CellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    deleting: boolean;
    dataIndex: string;
    title: any;
    inputType: 'number' | 'text';
    record: Invoice;
    index: number;
    children: React.ReactNode;
}

export const InvoicesTable = () => {
    const [invoices, setInvoices] = useState<PaginationList<Invoice>>({
        pagination: {
            pageSize: 10,
            pageIndex: 1,
            totalPages: 0,
            totalCount: 0,
            hasPreviousPage: false,
        },
        items: [],
    });
    const [query, setQuery] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false);
    const [searchLoading, setSearchLoading] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const [deletingKey, setDeletingKey] = useState('');
    const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
    const [invoiceInfo, setInvoiceInfo] = useState<InvoiceAddRequestBody>({
        amount: 0,
        rate: 0,
        symbol: {
            id: 0,
            title: ""
        },
        type: {
            id: 0,
            title: ""
        },
        description: "",
        txId: "",
        customerId: 0
    });

    const [customers, setCustomers] = useState<PaginationList<Customer>>()
    const [selectedCustomer, setSelectedCustomer] = useState<Customer>()

    const [types, setTypes] = useState<Enum[]>()
    const [selectedType, setSelectedType] = useState<Enum>()
    const [symbols, setSymbols] = useState<Enum[]>()
    const [selectedSymbol, setSelectedSymbol] = useState<Enum | number>()

    const initialFilters = {
        type: {
            id: -1,
            title: "انتخاب نوع تراکنش",
        },
        symbol: {
            id: -1,
            title: "انتخاب نوع ارز"
        }
    }

    const [filterValues, setFilterValues] = useState<{ type: Enum, symbol: Enum }>(initialFilters)

    const isEditing = (record: Invoice) => record.id === editingKey;
    const isDeleting = (record: Invoice) => record.id === deletingKey;

    const EditableCell: React.FC<CellProps> = ({
                                                   editing,
                                                   deleting,
                                                   dataIndex,
                                                   title,
                                                   inputType,
                                                   record,
                                                   index,
                                                   children
                                               }) => {
        let displayValue = children;

        if (editing) {

            return (
                <td>
                    <Form.Item
                        name={dataIndex}
                        style={{margin: 0}}
                        rules={[{required: true, message: `لطفاً مقدار را وارد نمایید.`}]}
                    >
                        {dataIndex === 'symbol' || dataIndex === 'type' ? (
                            <>
                                <span></span>
                                <Select
                                    defaultValue={dataIndex === 'symbol' ? record.symbol.title : record.type.title}
                                    onChange={(value) => {
                                        form.setFieldsValue({[dataIndex]: value});
                                    }}
                                >
                                    {dataIndex === 'symbol' ? symbols?.map((symbol: Enum) => (
                                        <Select.Option key={symbol.id} value={symbol.id}>
                                            {symbol.title}
                                        </Select.Option>
                                    )) : types?.map((type: Enum) => (
                                        <Select.Option key={type.id} value={type.id}>
                                            {type.title}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </>
                        ) : (
                            <Input/>
                        )}
                    </Form.Item>
                </td>
            );
        } else {
            if (dataIndex === 'type') {
                displayValue = record.type.title;
            } else if (dataIndex === 'symbol') {
                displayValue = record.symbol.title;
            }

            return <td>{displayValue}</td>;
        }
    };

    const getInvoices = (page: number = 1, pageSize: number = 10, setLoader: Dispatch<SetStateAction<boolean>>) => {
        setLoader(true);
        let url = `${API.invoice.get.all}/${page}/${pageSize}`;
        if (query !== '') {
            url += `${url.includes("?") ? "&" : "?"}keyword=${query}`;
        }
        if (filterValues.type && filterValues.type.id !== -1) {
            url += `${url.includes("?") ? "&" : "?"}type=${filterValues.type}`;
        }
        if (filterValues.symbol && filterValues.symbol.id !== -1) {
            url += `${url.includes("?") ? "&" : "?"}symbol=${filterValues.symbol}`;
        }

        getViaAuth<PaginationList<Invoice>>(url).then((response: any) => {
            if (responseValidator(response.status) && response.value) {
                setInvoices({
                    pagination: {
                        ...response.value.pagination,
                    },
                    items: response.value.items,
                });
            } else {
                message.error(response.message);
            }
            setLoader(false);
        });
    };

    const getCustomers = (page: number = 1, pageSize: number = 999) => {
        setLoading(true);
        let url = `${API.customer.get.all}/${page}/${pageSize}`;

        getViaAuth<PaginationList<Customer>>(url).then((response: any) => {
            if (responseValidator(response.status) && response.value) {
                setCustomers({
                    pagination: {
                        ...response.value.pagination,
                    },
                    items: response.value.items,
                });
            } else {
                message.error(response.message);
            }
            setLoading(false);
        });
    };

    const getTypes = () => {
        setLoading(true)
        getViaAuth(API.invoice.enum.type).then((response: any) => {
            setLoading(false)
            if (responseValidator(response.status)) {
                setTypes(response.value)
            }
        })
    }

    const getSymbols = () => {
        setLoading(true)
        getViaAuth(API.invoice.enum.symbol).then((response: any) => {
            setLoading(false)
            if (responseValidator(response.status)) {
                setSymbols(response.value)
            }
        })
    }


    useEffect(() => {
        getTypes();
        getSymbols();
        getInvoices(1, 10, setLoading);
        getCustomers()
    }, []);

    const handleSearch = () => {
        getInvoices(1, invoices.pagination.pageSize, setSearchLoading);
    };

    const create = () => {
        setLoading(true)
        const body = {
            amount: invoiceInfo.amount,
            rate: (invoiceInfo.rate === 0 || selectedSymbol === 3) ? null : invoiceInfo.rate,
            symbol: selectedSymbol,
            type: selectedType,
            description: invoiceInfo.description,
            txId: invoiceInfo.txId,
            customerId: selectedCustomer
        }
        postViaAuth(API.invoice.add, body).then((response: any) => {
            setLoading(false)
            if (responseValidator(response.status)) {
                message.success('افزودن با موفقیت انجام شد.');
                getInvoices(1, 10, setLoading);
                setIsCreateModalOpen(false)
                setInvoiceInfo({
                    amount: 0,
                    rate: 0,
                    symbol: {
                        id: 0,
                        title: ""
                    },
                    type: {
                        id: 0,
                        title: ""
                    },
                    description: "",
                    txId: "",
                    customerId: 0
                });
            } else message.error(response?.message);
        })
    }

    const edit = (record: Invoice) => {
        form.setFieldsValue({
            ...record,
        });
        setEditingKey(record.id);
    };

    const remove = (record: Invoice) => {
        setDeletingKey(record.id)
    }

    const confirmRemove = (record: Invoice) => {
        setLoading(true)
        del(API.invoice.delete, {id: record.id}).then((response: any) => {
            setLoading(false)
            if (responseValidator(response.status)) {
                getInvoices(1, 10, setLoading);
                message.success('حذف با موفقیت انجام شد.');
            } else message.error(response?.message);
        })
    }

    const cancel = () => {
        setEditingKey('');
        setDeletingKey('')
    };

    const save = async (record: string) => {
        setLoading(true);
        try {
            const row = await form.validateFields();
            const body = {
                ...row,
                id: record,
                type: row.type.id,
                symbol: row.symbol.id
            };
            const response = await postViaAuth(API.invoice.edit, body);
            if (responseValidator(response.status)) {
                message.success('ویرایش با موفقیت انجام شد.');
                getInvoices(1, 10, setLoading);
            } else {
                message.error(response?.message);
            }
        } catch (error) {
            message.error('خطایی رخ داده است.');
        } finally {
            setLoading(false);
            setEditingKey('');
        }
    };

    const columns = [
        {
            title: 'نوع تراکنش',
            dataIndex: 'type',
            width: '10%',
            inputType: "text",
            editable: true,
        },
        {
            title: 'نام',
            dataIndex: 'name',
            width: '10%',
            inputType: "text",
            editable: true,
        },
        {
            title: 'شماره تلفن',
            dataIndex: 'phoneNumber',
            width: '10%',
            inputType: "text",
            editable: true,
        },
        {
            title: 'نوع ارز',
            dataIndex: 'symbol',
            width: '10%',
            inputType: "text",
            editable: true,
        },
        {
            title: 'مقدار',
            dataIndex: 'amount',
            width: '10%',
            inputType: "text",
            editable: true,
            render: (_, record: Invoice) => <span>{digitSeparator(record?.amount)}</span>
        },
        {
            title: 'نرخ',
            dataIndex: 'rate',
            width: '10%',
            inputType: "text",
            editable: true,
            render: (_, record: Invoice) => <span>{digitSeparator(record?.rate)}</span>,
        },
        {
            title: 'هش تراکنش',
            dataIndex: 'txId',
            width: '15%',
            editable: true,
        },
        {
            title: 'توضیحات',
            dataIndex: 'description',
            width: '15%',
            inputType: "text",
            editable: true,
        },
        {
            title: 'عملیات',
            dataIndex: 'actions',
            render: (_: any, record: Invoice) => {
                const editable = isEditing(record);
                const deletable = isDeleting(record);

                return editable ? (
                    <span>
                        <Typography.Link onClick={() => save(record.id)}>ذخیره</Typography.Link>
                        <Typography.Link onClick={() => cancel()}>انصراف</Typography.Link>
                    </span>
                ) : deletable ? (
                    <span>
                        <Typography.Link onClick={() => confirmRemove(record)}>حذف</Typography.Link>
                        <Typography.Link onClick={() => cancel()}>انصراف </Typography.Link>
                    </span>
                ) : (
                    <span>
                            <Typography.Link disabled={editingKey !== ''}
                                             onClick={() => edit(record)}><EditOutlined/></Typography.Link>
                        {/*<Typography.Link onClick={() => remove(record)}><DeleteOutlined/></Typography.Link>*/}
                        </span>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: Invoice) => ({
                record,
                inputType: 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                deleting: isDeleting(record),
            }),
        };
    });

    return (
        <div className={"nf-invoices-table"}>
            <Modal
                open={isCreateModalOpen}
                title={"افزودن فاکتور"}
                okButtonProps={{style: {backgroundColor: '#6941a0', color: 'white'}}}
                okText={"افزودن"}
                onOk={create}
                onCancel={() => {
                    setInvoiceInfo({
                        amount: 0,
                        rate: 0,
                        symbol: {
                            id: 0,
                            title: ""
                        },
                        type: {
                            id: 0,
                            title: ""
                        },
                        description: "",
                        txId: "",
                        customerId: 0
                    });
                    setIsCreateModalOpen(false);
                }}
                cancelText={"لغو"}
                className={"create-invoice-modal"}
            >
                <label>کاربر</label>
                <Select
                    allowClear
                    value={selectedCustomer}
                    placeholder='کاربر را انتخاب کنید'
                    options={customers?.items?.map(customer => ({
                        label: `${customer.firstName} ${customer.lastName} - ${customer.customerCode} - ${customer.phoneNumber}`,
                        value: customer.id
                    }))}
                    showSearch
                    filterOption={(input, option) => (
                        option.label.toLowerCase().includes(input.toLowerCase())
                    )
                    }
                    onChange={(value, option) => {
                        setSelectedCustomer(value);
                    }}
                />
                <Row>
                    <Col>
                        <label>نوع</label>
                        <Select
                            allowClear
                            value={selectedType}
                            placeholder='نوع تراکنش را انتخاب کنید'
                            options={types?.map(type => ({
                                label: type.title,
                                value: type.id
                            }))}
                            onChange={(value, option) => {
                                setSelectedType(value);
                            }}
                        />
                    </Col>
                    <Col>
                        <label>نوع ارز</label>
                        <Select
                            allowClear
                            value={selectedSymbol}
                            placeholder='نوع ارز را انتخاب کنید'
                            options={symbols?.map(symbol => ({
                                label: symbol.title,
                                value: symbol.id
                            }))}
                            onChange={(value, option) => {
                                setSelectedSymbol(value);
                            }}
                        />
                    </Col>
                </Row>
                <label>مقدار</label>
                <Input type={"text"}
                       value={digitSeparator(invoiceInfo.amount)}
                       onChange={(e) => setInvoiceInfo({
                           ...invoiceInfo,
                           amount: Number(deleteCommaSeparator(e.target.value))
                       })}/>
                {symbols && selectedSymbol !== 3 &&
                    <>
                        <label>نرخ</label>
                        <Input type={"text"}
                               value={digitSeparator(invoiceInfo.rate)}
                               onChange={(e) => setInvoiceInfo({
                                   ...invoiceInfo,
                                   rate: Number(deleteCommaSeparator(e.target.value))
                               })}/>
                    </>
                }
                {symbols && selectedSymbol === 1 &&
                    <>
                        <label>هش تراکنش</label>
                        <Input type={"text"} onChange={(e) => setInvoiceInfo({...invoiceInfo, txId: e.target.value})}/>
                    </>
                }
                <label>توضیحات</label>
                <AntdInput.TextArea onChange={(e) => setInvoiceInfo({...invoiceInfo, description: e.target.value})}/>
            </Modal>
            <Form form={form} component={false}>
                <div className={"actions"}>
                    <div className={"search-container"}>
                        <Input placeholder={"جستجو کنید..."} onChange={(e) => setQuery(e.target.value)}
                               value={query}
                               onKeyDown={(e) => {
                                   if (e.key === 'Enter') {
                                       handleSearch();
                                   }
                               }}
                        />
                        <Select
                            allowClear
                            value={filterValues.type}
                            placeholder='نوع تراکنش را انتخاب کنید'
                            options={types?.map(type => ({
                                label: type.title,
                                value: type.id
                            }))}
                            onChange={(value, option) => {
                                setFilterValues({...filterValues, type: value});
                            }}
                        />
                        <Select
                            allowClear
                            value={filterValues?.symbol}
                            placeholder='نوع ارز را انتخاب کنید'
                            options={symbols?.map(symbol => ({
                                label: symbol.title,
                                value: symbol.id
                            }))}
                            onChange={(value, option) => {
                                setFilterValues({...filterValues, symbol: value})
                            }}
                        />
                        <Button
                            icon={<SearchOutlined/>}
                            variant={ButtonType.Primary}
                            text={"جستجو"}
                            onClick={handleSearch}
                            loading={searchLoading}
                        />
                    </div>
                    <Button variant={ButtonType.Primary} text={"افزودن فاکتور"}
                            onClick={() => setIsCreateModalOpen(true)}/>
                </div>
                <Table
                    components={{
                        body: {cell: EditableCell},
                    }}
                    bordered
                    dataSource={invoices.items}
                    loading={loading}
                    columns={mergedColumns}
                    pagination={{
                        total: invoices.pagination.totalCount,
                        pageSize: invoices.pagination.pageSize,
                        current: invoices.pagination.pageIndex,
                        onChange: (page, pageSize) => {
                            getInvoices(page, pageSize, setLoading);
                        },
                        pageSizeOptions: ['10', '20', '30'],
                        showSizeChanger: true,
                        locale: {
                            items_per_page: 'مورد در هر صفحه',
                            page: 'صفحه',
                            prev_page: 'صفحه قبلی',
                            next_page: 'صفحه بعدی',
                        },
                    }}
                />
            </Form>
        </div>
    );
};
