import {
    KEY_ACCESS_TOKEN,
    KEY_DATE,
    KEY_EXPIRES_IN,
    KEY_IS_AUTHORIZED,
    KEY_IS_TWO_FACTOR_ENABLED,
    KEY_LEVEL,
    KEY_PHONE,
    KEY_PROFILE_DATA,
    KEY_REFRESH,
    KEY_ROLES,
} from "core/constants/localStorageConstants";
import moment from "jalali-moment";

export function setLocalStorageItems(data) {
    localStorage.setItem(KEY_ACCESS_TOKEN, data?.accessToken);
    data?.refresh_token && localStorage.setItem(KEY_REFRESH, data?.refreshToken);
    localStorage.setItem(KEY_DATE, moment().utc().toString());
    data?.expiresIn &&
    localStorage.setItem(KEY_EXPIRES_IN, data?.expiresIn.toString());
    localStorage.setItem(KEY_IS_AUTHORIZED, data?.isAuthorized?.toString());
    localStorage.setItem(KEY_IS_TWO_FACTOR_ENABLED, data?.isTwoFactorEnabled)
    data?.roles && localStorage.setItem(KEY_ROLES, data?.roles.join(","));
}

export function clearTokenFromLocalStorage() {
    localStorage.removeItem(KEY_ACCESS_TOKEN);
    localStorage.removeItem(KEY_EXPIRES_IN);
    localStorage.removeItem(KEY_DATE);
    localStorage.removeItem(KEY_IS_AUTHORIZED);
    localStorage.removeItem(KEY_PHONE);
    localStorage.removeItem(KEY_REFRESH);
    localStorage.removeItem(KEY_ROLES);
    localStorage.removeItem(KEY_IS_TWO_FACTOR_ENABLED)
}
