import './dashboard.scss';
import {Sidebar} from "../../components/sidebar/sidebar";
import {ReactElement} from "react";
import {Navbar} from 'components/navbar/navbar';

interface DashboardProps {
    children?: ReactElement
}

export const Dashboard = (props: DashboardProps) => {
    return (
        <div className="nf-dashboard">
            <Sidebar/>
            <div className="nf-main">
                <Navbar/>
                {props.children}
            </div>
        </div>
    )
}