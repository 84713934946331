import "./login.scss"
import {Input} from "../../components/input/input";
import {Button, ButtonType} from "../../components/button/button";
import ReactCodeInput from "react-verification-code-input";
import {useState} from "react";
import {ToEn} from "../../core/utils/decimalExtentions";
import {useNavigate} from "react-router-dom";
import {LoginRequest} from "../../core/domain/login";
import {login} from "../../core/repositories/login";

export const Login = () => {

    const navigate = useNavigate()

    const [loading, setLoading] = useState<boolean>(false)
    const [loginInfo, setLoginInfo] = useState<LoginRequest>({
        phoneNumber: "",
        password: "",
        code: ""
    })

    const handleLogin = async () => {
        await login(setLoading, loginInfo, () => navigate('/dashboard'))
    }

    return (
        <div className={"nf-login"}>
            <div className={"content"}>
                <h1>ورود</h1>
                <label htmlFor={"phoneNumber"}>شماره موبایل</label>
                <Input dir={"ltr"} id={"phoneNumber"} type={"text"} maxLength={11}
                       onChange={(e) => setLoginInfo({...loginInfo, phoneNumber: e.target.value})}/>
                <label htmlFor={"password"}>رمز عبور</label>
                <Input dir={"ltr"} id={"password"} type={'password'}
                       onChange={(e) => setLoginInfo({...loginInfo, password: e.target.value})}/>
                <label>کد Google Authenticator</label>
                <ReactCodeInput
                    className={`code-input`}
                    fieldWidth={48}
                    fieldHeight={48}
                    values={loginInfo.code.split("")}
                    autoFocus={true}
                    onChange={(value) => {
                        setLoginInfo({...loginInfo, code: ToEn(value)});
                    }}
                    fields={6}
                />
                <Button variant={ButtonType.Primary} text={"ورود"} onClick={handleLogin} loading={loading}
                        disabled={loading || loginInfo.code.length !== 6 || loginInfo.password.length === 0 || loginInfo.phoneNumber.length !== 11}/>
            </div>
        </div>
    )
}