import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import './customers-table.scss'
import {Button, ButtonType} from "../button/button";
import {Input} from "../input/input";
import {PaginationList} from "../../core/domain/commons/common";
import {Customer, CustomerAddRequestBody} from "../../core/domain/customer";
import {API} from "../../core/apis";
import {del, getViaAuth, postViaAuth, responseValidator} from "../../core/apis/api";
import {Col, Form, Input as AntdInput, message, Modal, Row, Table, Typography} from 'antd';
import {
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    SearchOutlined,
    InfoCircleOutlined,
    LoadingOutlined
} from "@ant-design/icons";
import {copyToClipboard} from "../../core/utils/copyToClipboard";
import {CustomerSummary} from "../../core/domain/invoice";
import {digitSeparator} from "../../core/utils/decimalExtentions";
import irt from '../../assets/currencies/irt.png'
import usdt from '../../assets/currencies/usdt.png'
import pm from '../../assets/currencies/pm.png'
import moment from "jalali-moment";

interface CellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    deleting: boolean;
    dataIndex: string;
    title: any;
    inputType: 'number' | 'text';
    record: Customer;
    index: number;
    children: React.ReactNode;
}

export const CustomersTable = () => {
    const [customers, setCustomers] = useState<PaginationList<Customer>>({
        pagination: {
            pageSize: 10,
            pageIndex: 1,
            totalPages: 0,
            totalCount: 0,
            hasPreviousPage: false,
        },
        items: [],
    });
    const [query, setQuery] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false);
    const [searchLoading, setSearchLoading] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState(-1);
    const [deletingKey, setDeletingKey] = useState(-1);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
    const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false);
    const [infoLoading, setInfoLoading] = useState<boolean>(false);
    const [customerSummary, setCustomerSummary] = useState<CustomerSummary>()
    const [customerInfo, setCustomerInfo] = useState<CustomerAddRequestBody>({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        description: '',
    });
    const [selectedCustomer, setSelectedCustomer] = useState<Customer>();

    const isEditing = (record: Customer) => record.id === editingKey;
    const isDeleting = (record: Customer) => record.id === deletingKey;

    const EditableCell: React.FC<CellProps> = ({
                                                   editing,
                                                   deleting,
                                                   dataIndex,
                                                   title,
                                                   inputType,
                                                   record,
                                                   index,
                                                   children
                                               }) => {
        return (
            <td>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{margin: 0}}
                        rules={[{required: true, message: `لطفاً مقدار را وارد نمایید.`}]}
                    >
                        <Input/>
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const getCustomers = (page: number = 1, pageSize: number = 10, setLoader: Dispatch<SetStateAction<boolean>>) => {
        setLoader(true);
        let url = `${API.customer.get.all}/${page}/${pageSize}`;
        if (query !== '') {
            url += `?keyword=${query}`;
        }
        getViaAuth<PaginationList<Customer>>(url).then((response: any) => {
            if (responseValidator(response.status) && response.value) {
                setCustomers({
                    pagination: {
                        ...response.value.pagination,
                    },
                    items: response.value.items,
                });
            } else {
                message.error(response.message);
            }
            setLoader(false);
        });
    };


    useEffect(() => {
        getCustomers(1, 10, setLoading);
    }, []);

    const handleSearch = () => {
        getCustomers(1, customers.pagination.pageSize, setSearchLoading);
    };

    const create = () => {
        setLoading(true)
        postViaAuth(API.customer.add, customerInfo).then((response: any) => {
            setLoading(false)
            if (responseValidator(response.status)) {
                message.success('افزودن با موفقیت انجام شد.');
                getCustomers(1, 10, setLoading);
                setIsCreateModalOpen(false)
                setCustomerInfo({
                    firstName: '',
                    lastName: '',
                    phoneNumber: '',
                    description: '',
                })
            } else message.error(response?.message);
        })
    }

    const edit = (record: Customer) => {
        form.setFieldsValue({
            ...record,
        });
        setEditingKey(record.id);
    };

    const info = (record: Customer) => {
        setSelectedCustomer(record)
        setIsInfoModalOpen(true)
        setInfoLoading(true)
        getViaAuth<CustomerSummary>(API.report.get.customerSummary, {CustomerId: record.id}).then((response: any) => {
                setInfoLoading(false);
                if (responseValidator(response.status)) {
                    setCustomerSummary(response.value)
                }
            }
        )
    }

    const remove = (record: Customer) => {
        setDeletingKey(record.id)
    }

    const confirmRemove = (record: Customer) => {
        setLoading(true)
        del(API.customer.delete, {id: record.id}).then((response: any) => {
            setLoading(false)
            if (responseValidator(response.status)) {
                getCustomers(1, 10, setLoading);
                message.success('حذف با موفقیت انجام شد.');
            } else message.error(response?.message);
        })
    }

    const cancel = () => {
        setEditingKey(-1);
        setDeletingKey(-1)
    };

    const save = async (record: number) => {
        setLoading(true);
        try {
            const row = await form.validateFields();
            const body = {
                id: record,
                ...row,
            };
            const response = await postViaAuth(API.customer.edit, body);
            if (responseValidator(response.status)) {
                message.success('ویرایش با موفقیت انجام شد.');
                getCustomers(1, 10, setLoading);
            } else {
                message.error(response?.message);
            }
        } catch (error) {
            console.error('Error occurred during save:', error);
            message.error('خطایی رخ داده است.');
        } finally {
            setLoading(false);
            setEditingKey(-1);
        }
    };

    const columns = [
        {
            title: 'نام',
            dataIndex: 'firstName',
            width: '10%',
            inputType: "text",
            editable: true,
        },
        {
            title: 'نام خانوادگی',
            dataIndex: 'lastName',
            width: '15%',
            inputType: "text",
            editable: true,
        },
        {
            title: 'شماره تلفن',
            dataIndex: 'phoneNumber',
            width: '15%',
            editable: true,
        },
        {
            title: 'کد مشتری',
            dataIndex: 'customerCode',
            width: '20%',
            inputType: "text",
            editable: true,
            render: (_, record: Customer) => {
                return (
                    <div>
                        <span className={"en"}>{record?.customerCode}</span>
                        <CopyOutlined onClick={() => copyToClipboard(record.customerCode, "کد مشتری")}/>
                    </div>
                )
            }
        },
        {
            title: 'توضیحات',
            dataIndex: 'description',
            width: '20%',
            inputType: "text",
            editable: true,
        },
        {
            title: 'عملیات',
            dataIndex: 'actions',
            render: (_: any, record: Customer) => {
                const editable = isEditing(record);
                const deletable = isDeleting(record);

                return editable ? (
                    <span>
                        <Typography.Link onClick={() => save(record.id)}>ذخیره</Typography.Link>
                        <Typography.Link onClick={() => cancel()}>انصراف</Typography.Link>
                    </span>
                ) : deletable ? (
                    <span>
                        <Typography.Link onClick={() => confirmRemove(record)}>حذف</Typography.Link>
                        <Typography.Link onClick={() => cancel()}>انصراف </Typography.Link>
                    </span>
                ) : (
                    <span>
                            <Typography.Link disabled={editingKey !== -1}
                                             onClick={() => edit(record)}><EditOutlined/>
                            </Typography.Link>
                            <Typography.Link
                                onClick={() => info(record)}><InfoCircleOutlined/>
                            </Typography.Link>
                        {/*<Typography.Link onClick={() => remove(record)}><DeleteOutlined/></Typography.Link>*/}
                        </span>
                );
            },
        },
    ];

    const info_customer_columns = [
        {
            title: 'نام ارز',
            dataIndex: 'currency',
            key: 'currency',
        },
        {
            title: 'مانده حساب',
            dataIndex: 'balance',
            key: 'balance',
        },
        {
            title: 'وضع',
            dataIndex: 'status',
            key: 'status',
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: Customer) => ({
                record,
                inputType: 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                deleting: isDeleting(record),
            }),
        };
    });

    return (
        <div className={"nf-customers-table"}>
            <Modal
                open={isCreateModalOpen}
                title={"افزودن مشتری"}
                okButtonProps={{style: {backgroundColor: '#6941a0', color: 'white'}}}
                okText={"افزودن"}
                onOk={create}
                onCancel={() => {
                    setCustomerInfo({
                        firstName: '',
                        lastName: '',
                        phoneNumber: '',
                        description: '',
                    });
                    setIsCreateModalOpen(false);
                }}
                cancelText={"لغو"}
                className={"create-customer-modal"}
            >
                <Row>
                    <Col>
                        <label>نام</label>
                        <Input type={"text"}
                               onChange={(e) => setCustomerInfo({...customerInfo, firstName: e.target.value})}/>
                    </Col>
                    <Col>
                        <label>نام خانوادگی</label>
                        <Input type={"text"}
                               onChange={(e) => setCustomerInfo({...customerInfo, lastName: e.target.value})}/>
                    </Col>
                </Row>
                <label>شماره تلفن</label>
                <Input type={"text"} onChange={(e) => setCustomerInfo({...customerInfo, phoneNumber: e.target.value})}
                       maxLength={11}/>
                <label>توضیحات</label>
                <AntdInput.TextArea onChange={(e) => setCustomerInfo({...customerInfo, description: e.target.value})}/>
            </Modal>
            <Modal
                open={isInfoModalOpen}
                title={selectedCustomer ? `صورتحساب ${selectedCustomer.firstName} ${selectedCustomer.lastName} | ${moment().locale('fa').format('dddd jD jMMMM jYYYY، ساعت HH:mm')}` : `صورتحساب مشتری `}
                okButtonProps={{style: {backgroundColor: '#6941a0', color: 'white'}}}
                okText={"تایید"}
                cancelText={"بستن"}
                onOk={() => {
                    setIsInfoModalOpen(false);
                    setCustomerSummary(undefined)
                    setSelectedCustomer(undefined)
                }}
                onCancel={() => {
                    setIsInfoModalOpen(false);
                    setCustomerSummary(undefined)
                    setSelectedCustomer(undefined)
                }}
                className={"info-customer-modal"}
            >
                <Table
                    columns={info_customer_columns}
                    dataSource={[
                        {
                            key: '1',
                            currency: <Row><img src={irt} width={32} height={32}/> <span>تومان</span></Row>,
                            balance: infoLoading ? <LoadingOutlined/> : (
                                <span dir={"ltr"}
                                      style={{color: customerSummary?.irt > 0 ? 'green' : customerSummary?.irt < 0 ? 'red' : 'black'}}>
                {digitSeparator(customerSummary?.irt)}
              </span>
                            ),
                            status: infoLoading ? <LoadingOutlined/> : (
                                <span
                                    style={{color: customerSummary?.irt > 0 ? 'green' : customerSummary?.irt < 0 ? 'red' : 'black'}}>
                {customerSummary?.irt > 0 ? 'خوب' : customerSummary?.irt < 0 ? 'بد' : ' - '}
              </span>
                            ),
                        },
                        {
                            key: '2',
                            currency: <Row><img src={pm} width={32} height={32}/> <span>پرفکت مانی</span></Row>,
                            balance: infoLoading ? <LoadingOutlined/> : (
                                <span dir={"ltr"}
                                      style={{color: customerSummary?.pm > 0 ? 'green' : customerSummary?.pm < 0 ? 'red' : 'black'}}>
                {digitSeparator(customerSummary?.pm)}
              </span>
                            ),
                            status: infoLoading ? <LoadingOutlined/> : (
                                <span
                                    style={{color: customerSummary?.pm > 0 ? 'green' : customerSummary?.pm < 0 ? 'red' : 'black'}}>
                {customerSummary?.pm > 0 ? 'خوب' : customerSummary?.pm < 0 ? 'بد' : ' - '}
              </span>
                            ),
                        },
                        {
                            key: '3',
                            currency: <Row><img src={usdt} width={32} height={32}/> <span>تتر</span></Row>,
                            balance: infoLoading ? <LoadingOutlined/> : (
                                <span dir={"ltr"}
                                      style={{color: customerSummary?.tether > 0 ? 'green' : customerSummary?.tether < 0 ? 'red' : 'black'}}>
                {digitSeparator(customerSummary?.tether)}
              </span>
                            ),
                            status: infoLoading ? <LoadingOutlined/> : (
                                <span
                                    style={{color: customerSummary?.tether > 0 ? 'green' : customerSummary?.tether < 0 ? 'red' : 'black'}}>
                {customerSummary?.tether > 0 ? 'خوب' : customerSummary?.tether < 0 ? 'بد' : ' - '}
              </span>
                            ),
                        },
                    ]}
                    pagination={false}
                />
            </Modal>
            <Form form={form} component={false}>
                <div className={"actions"}>
                    <div className={"search-container"}>
                        <Input placeholder={"جستجو کنید..."} onChange={(e) => setQuery(e.target.value)}
                               value={query}
                               onKeyDown={(e) => {
                                   if (e.key === 'Enter') {
                                       handleSearch();
                                   }
                               }}/>
                        <Button
                            icon={<SearchOutlined/>}
                            variant={ButtonType.Primary}
                            text={"جستجو"}
                            onClick={handleSearch}
                            loading={searchLoading}
                        />
                    </div>
                    <Button variant={ButtonType.Primary} text={"افزودن مشتری"}
                            onClick={() => setIsCreateModalOpen(true)}/>
                </div>
                <Table
                    components={{
                        body: {cell: EditableCell},
                    }}
                    bordered
                    dataSource={customers.items}
                    loading={loading}
                    columns={mergedColumns}
                    pagination={{
                        total: customers.pagination.totalCount,
                        pageSize: customers.pagination.pageSize,
                        current: customers.pagination.pageIndex,
                        onChange: (page, pageSize) => {
                            getCustomers(page, pageSize, setLoading);
                        },
                        pageSizeOptions: ['10', '20', '30'],
                        showSizeChanger: true,
                        locale: {
                            items_per_page: 'مورد در هر صفحه',
                            page: 'صفحه',
                            prev_page: 'صفحه قبلی',
                            next_page: 'صفحه بعدی',
                        },
                    }}
                />
            </Form>
        </div>
    );
};

