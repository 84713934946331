import './button.scss';
import {ButtonHTMLAttributes} from "react";
import {LoadingOutlined} from "@ant-design/icons";

export enum ButtonType {
    Primary = 1,
    Secondary,
    Outlined,
    Logout
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    text?: string;
    icon?: React.ReactNode;
    variant?: ButtonType;
    loading?: boolean;
}

export const Button = (props: ButtonProps) => {
    return (
        <button className={`nf-button ${props.variant ? ButtonType[props.variant].toLowerCase() : ""}`} {...props}>
            {props.text ?
                <span>{props.icon ? props.icon : null}{props.text}{props.loading ? <LoadingOutlined /> : null}</span> : null}
        </button>
    )
}