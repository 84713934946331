import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import NF from './nf';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <NF />
);


reportWebVitals();
