export function ToFa(value: any) {
    const persionDigits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
    return value.toString().replace(/\d/g, (x: any) => persionDigits[Number(x)]);
}

export function ToEn(value: any) {
    let str = value.replace(",", "");
    const persionDigits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
    for (var i = 0; i < 10; i++) {
        str = str.replaceAll(persionDigits[i], i);
    }
    return str;
}

export function faNumToEnNum(value: any): any {
    let str = value.replace(",", "");
    const persionDigits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
    for (var i = 0; i < 10; i++) {
        str = str.replaceAll(persionDigits[i], i);
    }
    return str;
}

export function deleteCommaSeparator(value: any): any {
    if (!value && value !== 0) return value;

    return value.toString().replaceAll(",", "");
}

export function changeDecimal(value: string, decimalNumber: number): any {
    let wholeNum = value.toString().split(".")[0];
    let decimalNum = value.toString().split(".")[1];
    // check has extra decimalNum
    if (!decimalNum || (decimalNumber && decimalNum.length < decimalNumber))
        return value;

    if (decimalNumber !== 0) {
        decimalNum = decimalNum.substring(0, decimalNumber);
        //remove extra zero
        for (let i = decimalNum.length - 1; i < decimalNum.length; i--) {
            if (decimalNum[i] === "0")
                decimalNum =
                    decimalNum.substring(0, i - 1) +
                    decimalNum.substring(i, decimalNum.length);
            else break;
        }

        var changedNum = parseFloat(wholeNum + "." + decimalNum);

        return changedNum;
    } else return wholeNum;
}

export function digitSeparator(value: any): string {
    if (!value && value !== 0) return value; // Handle null, undefined, and empty string

    let [integerPart, decimalPart] = value.toString().split(".");

    // Add commas to the integer part
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Return the number with the original decimal part (if it exists)
    return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
}

export function ToIranCurrency(num: any) {
    var str = num.toString().split(".");
    if (str[0].length >= 4) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    }
    if (str[1] && str[1].length >= 4) {
        str[1] = str[1].replace(/(\d{3})/g, "$1 ");
    }
    return str.join(".");
}

export function IsNumericText(value: any) {
    var regex = /^(([0-9]*)|(([0-9]*)\.([0-9]*)))$/;
    if (!regex.test(value)) {

        return false;
    }
    return true;
}

export function IsNumeric(num: any) {
    if (
        num === "." ||
        num === "" ||
        num === "0" ||
        num === "1" ||
        num === "2" ||
        num === "3" ||
        num === "4" ||
        num === "5" ||
        num === "6" ||
        num === "7" ||
        num === "8" ||
        num === "9"
    ) {
        return true;
    } else return false;
}
