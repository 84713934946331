import './invoices.scss'
import {InvoicesTable} from "../../../components/invoice-table/invoices-table";

export const Invoices = () => {
    return (
        <div className={"nf-invoices"}>
            <h1>فاکتور ها</h1>
            <InvoicesTable />
        </div>
    )
}