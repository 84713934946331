import {postGeneric, responseValidator} from "../apis/api";
import {API} from "../apis";
import {LoginRequest, LoginResponse} from "../domain/login";
import {Dispatch, SetStateAction} from "react";
import {setLocalStorageItems} from "../utils/setLocalStorageItems";
import toast from "react-hot-toast";

export const login = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    data: LoginRequest,
    onComplete: () => void,
) => {
    setLoading(true)
    await postGeneric<LoginResponse>(API.user.login.verifyCodeOrPw, data).then((response: any) => {
        setLoading(false)
        if (responseValidator(response.status)) {
            setLocalStorageItems(response.value)
            toast.success("ورود شما موفقیت آمیز بود.")
            onComplete()
        }
    })
}