import './reports.scss'
import {ReportsTable} from "../../../components/reports-table/reports-table";

export const Reports = () => {
    return (
        <div className={"nf-reports"}>
            <h1>گزارش ها</h1>
            <ReportsTable />
        </div>
    )
}