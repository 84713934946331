import './navbar.scss';
import moment from "jalali-moment";
import {Button, ButtonType} from "../button/button";
import {LogoutOutlined} from "@ant-design/icons"
import {useNavigate} from "react-router-dom";
import {clearTokenFromLocalStorage} from "../../core/utils/setLocalStorageItems";

export const Navbar = () => {

    const navigate = useNavigate()

    const handleLogout = () => {
        clearTokenFromLocalStorage()
        navigate('/login')
    }

    return (
        <div className={"nf-navbar"}>
            <div className={"column"}>
                <h2>ادمین عزیز، خوش آمدید.</h2>
                <span>{moment().locale('fa').format('dddd jD jMMMM jYYYY، ساعت HH:mm')}</span>
            </div>
            <Button text={"خروج"} icon={<LogoutOutlined/>} variant={ButtonType.Logout} onClick={handleLogout}/>
        </div>
    )
}