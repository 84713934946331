import {CustomersTable} from "../../../components/customers-table/customers-table";
import './customers.scss';

export const Customers = () => {

    return (
        <div className={"nf-customers"}>
            <h1>مشتریان</h1>
            <CustomersTable />
        </div>
    )
}