import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import './reports-table.scss'
import {Button, ButtonType} from "../button/button";
import {Input} from "../input/input";
import {Enum, PaginationList} from "../../core/domain/commons/common";
import {API} from "../../core/apis";
import {del, getViaAuth, postViaAuth, responseValidator} from "../../core/apis/api";
import {Col, Form, Input as AntdInput, message, Modal, Row, Select, Table, Typography} from 'antd';
import {CopyOutlined, DeleteOutlined, EditOutlined, SearchOutlined} from "@ant-design/icons";
import {Report} from "../../core/domain/invoice";
import {Customer} from "../../core/domain/customer";
import {deleteCommaSeparator, digitSeparator} from "core/utils/decimalExtentions";
import {copyToClipboard} from "../../core/utils/copyToClipboard";

export const ReportsTable = () => {
    const [reports, setReports] = useState<PaginationList<Report>>({
        pagination: {
            pageSize: 10,
            pageIndex: 1,
            totalPages: 0,
            totalCount: 0,
            hasPreviousPage: false,
        },
        items: [],
    });

    const [query, setQuery] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false);
    const [searchLoading, setSearchLoading] = useState<boolean>(false);

    const getReports = (page: number = 1, pageSize: number = 10, setLoader: Dispatch<SetStateAction<boolean>>) => {
        setLoader(true);
        let url = `${API.report.get.customersSummary}/${page}/${pageSize}`;
        if (query !== '') {
            url += `?keyword=${query}`;
        }

        getViaAuth<PaginationList<Report>>(url).then((response: any) => {
            if (responseValidator(response.status) && response.value) {
                setReports({
                    pagination: {
                        ...response.value.pagination,
                    },
                    items: response.value.items,
                });
            } else {
                message.error(response.message);
            }
            setLoader(false);
        });
    };

    useEffect(() => {
        getReports(1, 10, setLoading);
    }, []);

    const handleSearch = () => {
        getReports(1, reports.pagination.pageSize, setSearchLoading);
    };

    const columns = [
        {
            title: 'مشتری',
            dataIndex: 'customer',
            width: '10%',
            inputType: "text",
            render: (_, record: Report) => {
                return (
                    <div className={"customer-info"}>
                        <span>{record.firstName + " " + record.lastName}</span>
                        <span>{record.phoneNumber}</span>
                    </div>
                )
            }
        },
        {
            title: 'کد مشتری',
            dataIndex: 'customerCode',
            width: '10%',
            inputType: "text",
            render: (_, record: Report) => {
                return (
                    <div>
                        <span className={"en"}>{record?.customerCode}</span>
                        <CopyOutlined onClick={() => copyToClipboard(record.customerCode, "کد مشتری")}/>
                    </div>
                )
            }
        },
        {
            title: 'تتر',
            dataIndex: 'tether',
            width: '10%',
            inputType: "text",
            render: (_, record: Report) => <span dir={"ltr"} style={{ color: `${record.tether === 0 ? 'black' : record.tether > 0 ? 'green' : 'red'}` }}>{digitSeparator(record?.tether)}</span>
        },
        {
            title: 'تومان',
            dataIndex: 'irt',
            width: '10%',
            inputType: "text",
            render: (_, record: Report) => <span dir={"ltr"} style={{ color: `${record.irt === 0 ? 'black' : record.irt > 0 ? 'green' : 'red'}` }}>{digitSeparator(record?.irt)}</span>
        },
        {
            title: 'پرفکت مانی',
            dataIndex: 'pm',
            width: '10%',
            inputType: "text",
            render: (_, record: Report) => <span dir={"ltr"} style={{ color: `${record.pm === 0 ? 'black' : record.pm > 0 ? 'green' : 'red'}` }}>{digitSeparator(record?.pm)}</span>
        },
    ];

    return (
        <div className={"nf-reports-table"}>
            <div className={"actions"}>
                <div className={"search-container"}>
                    <Input placeholder={"جستجو کنید..."} onChange={(e) => setQuery(e.target.value)}
                           value={query}
                           onKeyDown={(e) => {
                               if (e.key === 'Enter') {
                                   handleSearch();
                               }
                           }}
                    />
                    <Button
                        icon={<SearchOutlined/>}
                        variant={ButtonType.Primary}
                        text={"جستجو"}
                        onClick={handleSearch}
                        loading={searchLoading}
                    />
                </div>
            </div>
            <Table
                bordered
                dataSource={reports.items}
                loading={loading}
                columns={columns}
                pagination={{
                    total: reports.pagination.totalCount,
                    pageSize: reports.pagination.pageSize,
                    current: reports.pagination.pageIndex,
                    onChange: (page, pageSize) => {
                        getReports(page, pageSize, setLoading);
                    },
                    pageSizeOptions: ['10', '20', '30'],
                    showSizeChanger: true,
                    locale: {
                        items_per_page: 'مورد در هر صفحه',
                        page: 'صفحه',
                        prev_page: 'صفحه قبلی',
                        next_page: 'صفحه بعدی',
                    },
                }}
            />
        </div>
    );
};
