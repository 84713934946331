import {useLocation, useNavigate} from 'react-router-dom';
import './sidebar.scss';
import {Button, ButtonType} from "../button/button";
import {HomeOutlined, UserOutlined, FileTextOutlined} from "@ant-design/icons"

export const Sidebar = () => {

    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className={"nf-sidebar"}>
            <div className={"sidebar-head"}>
                <h1>پیشخوان</h1>
            </div>
            <div className={"sidebar-body"}>
                <ul>
                    <li onClick={() => navigate("/dashboard")} className={`${location.pathname === "/dashboard" ? "active" : ""}`}>
                        <Button variant={ButtonType.Primary} icon={<HomeOutlined/>} text="صفحه اصلی"/>
                    </li>
                    <li onClick={() => navigate("/dashboard/customers")}
                        className={`${location.pathname === "/dashboard/customers" ? "active" : ""}`}>
                        <Button variant={ButtonType.Primary} icon={<UserOutlined />} text="مشتریان"/>
                    </li>
                    <li onClick={() => navigate("/dashboard/invoices")}
                        className={`${location.pathname === "/dashboard/invoices" ? "active" : ""}`}>
                        <Button variant={ButtonType.Primary} icon={<FileTextOutlined />} text="فاکتور ها"/>
                    </li>
                    <li onClick={() => navigate("/dashboard/reports")}
                        className={`${location.pathname === "/dashboard/reports" ? "active" : ""}`}>
                        <Button variant={ButtonType.Primary} icon={<FileTextOutlined />} text="گزارش ها"/>
                    </li>
                </ul>
            </div>
        </div>
    )
}