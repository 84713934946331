type apiParams = string | number;

export const __API = {
  user: {
    login: {
      verifyCodeOrPw: "Admin/User/SignIn"
    },
    token: "",
  },
  customer: {
    get: {
      all: "Customer/All"
    },
    add: "Customer/Add",
    edit: "Customer/Edit",
    delete: "Customer/Delete"
  },
  invoice: {
    get: {
      all: "Invoice/All"
    },
    add: "Invoice/Add",
    edit: "Invoice/Edit",
    delete: "Invoice/Delete",
    enum: {
      state: "Enum/InvoiceState",
      type: "Enum/InvoiceType",
      symbol: "Enum/InvoiceSymbol"
    }
  },
  report: {
    get: {
      customersSummary: "Invoice/GetCustomersSummary",
      customerSummary: "Invoice/GetCustomerSummary"
    }
  }
};
